import React, { useState } from "react";
import DropdownSelect from "../../../dropdown/Dropdown";
import TextInput from "../../../UIComponent/TextInput";
import moment from "moment";
import Validation from "../../../../utils/validation/ValidationText";
import Selector from "../../../dropdown/commonDropdown/Selector";
import ButtonLoader from "../../../common/buttonLoader/ButtonLoader";

function OrganisationBasicInformations(props) {
  function subtractYears(numOfYears, date = new Date()) {
    date.setFullYear(date.getFullYear() - numOfYears);
    return date;
  }
  // const handleButtonClick = () => {
  //   props.setLoginToCorporateModalTrue();
    
  // };


  return (
    <div className="w-full">
      <div className="2xl:px-10 2xl:py-10 2xl:pt-7 lg:px-7 px-3 lg:py-7 lg:pt-5 pt-4">
        {/* <button
          className="flex items-center col-start-7"
          onClick={handleButtonClick}
        >
          <span className="text-[#C00000] font-bold mx-1 2xl:text-[22px] lg:text-[18px] text-[13px] ">
            Edit CompanyName or UENnn
          </span>
          <img
            className="lg:w-7 md:w-5 w-4"
            src="/round-right-icon.png"
            alt=""
          />
        </button> */}

        <div className="grid grid-cols-12 gap-6 w-full ">
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  Company UEN/Business Registration No.{" "}
                  <span className="text-[#c00000]">*</span>
                </h2>
                <input
                  className={`w-full outline-none appearance-none ${
                    // props.organizationBasicInformation
                    //   .organisationInformationId > 0
                    // "bg-[#f2f2f2]"
                  ""
                  }`}
                  placeholder=""
                  type="text"
                  name="companyUEN"
                  identity="BasicInformation"
                  value={props.organizationBasicInformation.companyUEN}
                  onChange={(event) =>
                    props.handleChange(event, "BasicInformation")
                  }
                  onBlur={(e) =>
                    props.validate(
                      "companyUEN",
                      props.organizationBasicInformation
                    )
                  }
                  // disabled={props.isLoginToCorporateAllowed}
                  autoComplete={"off"}
                />
              </div>
            </div>
            <Validation error={props.validState.error.companyUEN} />
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  Company Name <span className="text-[#c00000]">*</span>
                </h2>
                <div className="dropdwn-input flex items-center">
                  <TextInput
                    className={`${
                      // props.organizationBasicInformation
                      //   .organisationInformationId > 0
                      // "bg-[#f2f2f2]"
                     ""
                    }`}
                    placeholder=""
                    type="text"
                    name="companyName"
                    value={props.organizationBasicInformation.companyName}
                    identity="BasicInformation"
                    handleChange={props.handleChange}
                    onBlur={(e) =>
                      props.validate(
                        "companyName",
                        props.organizationBasicInformation
                      )
                    }
                    // disabled={
                    //   props.organizationBasicInformation
                    //     .organisationInformationId > 0
                    // }
                    // disabled={props.isLoginToCorporateAllowed}
                    autoComplete={"off"}
                  />
                </div>
                <Validation error={props.validState.error.companyName} />
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  Incorporation Date<span className="text-[#c00000]">*</span>
                </h2>
                <input
                  className="date-pickers w-full custom-input "
                  type="date"
                  name="incorporationDate"
                  identity="BasicInformation"
                  max={moment(new Date()).format("yyyy-MM-DD")}
                  // min={props.membershipSubscription.membershipTypeId === 3 ? subtractYears(4, new Date()) : null}
                  value={moment(
                    props.organizationBasicInformation.incorporationDate
                  ).format("yyyy-MM-DD")}
                  onChange={(e) => props.handleChange(e, "BasicInformation")}
                  onBlur={(e) =>
                    props.validate(
                      "incorporationDate",
                      props.organizationBasicInformation
                    )
                  }
                ></input>
                <Validation error={props.validState.error.incorporationDate} />
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  Job Function <span className="text-[#c00000]">*</span>
                </h2>
                {/* <TextInput
                  placeholder=""
                  type="text"
                  name="designation"
                  value={props.organizationBasicInformation.designation}
                  identity="BasicInformation"
                  handleChange={props.handleChange}
                  onBlur={(e) => props.validate("designation", props.organizationBasicInformation)}
                /> */}
                <Selector
                  drpIdentity="Designation_BasicInfo"
                  options={props.designationList}
                  isMulti
                  //   options={props.countriesWithPresence}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  // components={{ Option }}
                  onChange={props.handleChangeMultiDropdown}
                  allowSelectAll={true}
                  value={props.organizationBasicInformation.selectedDesignation}
                  disabled={
                    props.organizationBasicInformation
                      .organisationInformationId > 0
                  }
                />
              </div>
            </div>
            <Validation error={props.validState.error.selectedDesignation} />
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  Attention To First Name
                  <span className="text-[#c00000]">*</span>
                </h2>

                <div className="flex items-center left-border-none">
                  <div className="w-[130px]">
                    <DropdownSelect
                      drpIdentity={"NamePrefix"}
                      optionArray={props.mrMrs}
                      setFilterParameters={props.setFilterParameters}
                      value={props.organizationBasicInformation.mrMrsId}
                      //disabled={props.organizationBasicInformation.organisationInformationId > 0 ? true : false}
                    />
                  </div>
                  <TextInput
                    placeholder=""
                    type="text"
                    name="firstName"
                    value={props.organizationBasicInformation.firstName}
                    identity="BasicInformation"
                    handleChange={props.handleChange}
                    onBlur={(e) =>
                      props.validate(
                        "firstName",
                        props.organizationBasicInformation
                      )
                    }
                    //disabled={props.organizationBasicInformation.organisationInformationId > 0 ? true : false}
                    //className={props.organizationBasicInformation.organisationInformationId > 0 ? `bg-[#f2f2f2]` : ""}
                  />
                </div>

                {/* <div className="dropdwn-input-email flex items-center">
                  <div className="w-[130px]">
                    <DropdownSelect
                      drpIdentity={"NamePrefix"}
                      optionArray={props.mrMrs}
                      setFilterParameters={props.setFilterParameters}
                      value={props.organizationBasicInformation.mrMrsId}
                    />

                  </div>
                  <TextInput
                    placeholder=""
                    type="text"
                    name="firstName"
                    value={props.organizationBasicInformation.firstName}
                    identity="BasicInformation"
                    handleChange={props.handleChange}
                    onBlur={(e) =>
                      props.validate(
                        "firstName",
                        props.organizationBasicInformation
                      )
                    }
                  />
                </div> */}
                <div>
                  <Validation error={props.validState.error.mrMrsId} />
                  <Validation error={props.validState.error.firstName} />
                </div>
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl theme-color font-bold 2xl:mb-1">
                  Attention To Last Name{" "}
                  <span className="text-[#c00000]">*</span>
                </h2>
                <TextInput
                  placeholder=""
                  type="text"
                  name="lastName"
                  value={props.organizationBasicInformation.lastName}
                  identity="BasicInformation"
                  handleChange={props.handleChange}
                  onBlur={(e) =>
                    props.validate(
                      "lastName",
                      props.organizationBasicInformation
                    )
                  }
                  //disabled={props.organizationBasicInformation.organisationInformationId > 0 ? true : false}
                  //className={props.organizationBasicInformation.organisationInformationId > 0 ? `bg-[#f2f2f2]` : ""}
                />
              </div>
            </div>
            <Validation error={props.validState.error.lastName} />
          </div>

          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  Email Address <span className="text-[#c00000]">*</span>
                </h2>
                <TextInput
                  placeholder=""
                  type="email"
                  name="email"
                  value={props.organizationBasicInformation.email}
                  identity="BasicInformation"
                  handleChange={props.handleChange}
                  onBlur={(e) =>
                    props.validate("email", props.organizationBasicInformation)
                  }
                  disabled={
                    props.organizationBasicInformation
                      .organisationInformationId > 0
                  }
                  className={
                    props.organizationBasicInformation
                      .organisationInformationId > 0
                      ? `bg-[#f2f2f2]`
                      : ""
                  }
                />
              </div>
            </div>
            <Validation error={props.validState.error.email} />
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  Business Contact Number{" "}
                  <span className="text-[#c00000]">*</span>
                </h2>
                <TextInput
                  placeholder=""
                  type="text"
                  name="contactNo"
                  maxLength={20}
                  value={props.organizationBasicInformation.contactNo}
                  identity="BasicInformation"
                  handleChange={props.handleChange}
                  onBlur={(e) =>
                    props.validate(
                      "contactNo",
                      props.organizationBasicInformation
                    )
                  }
                  autoComplete={"off"}
                  disabled={
                    props.organizationBasicInformation
                      .organisationInformationId > 0
                  }
                  className={
                    props.organizationBasicInformation
                      .organisationInformationId > 0
                      ? `bg-[#f2f2f2]`
                      : ""
                  }
                />
              </div>
            </div>
            <Validation error={props.validState.error.contactNo} />
          </div>
        </div>
      </div>
      {/* Save Button */}
      <div className="grid grid-cols-12 gap-6 pb-10">
        <div className="xl:col-span-12 lg:col-span-12 col-span-12 2xl:px-10 xl:px-10 lg:px-8 md:px-3 sm:px-4 px-4 mt-4">
          <a
            className="flex items-center justify-end"
            //href="/CreateCorporateMembers"
          >
            {props && props.isLoading === true ? (
              <ButtonLoader />
            ) : (
              <button
                className="w-32 px-7 py-3 btn-sm btn-red text-xl text-white font-semibold"
                onClick={() => props.onSave()}
              >
                Save
              </button>
            )}
          </a>
        </div>
      </div>
    </div>
  );
}

export default OrganisationBasicInformations;
